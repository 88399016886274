<template>
  <div id="product-view" class="mt-2">
    <!-- <div class="d-flex justify-content-center">
      <img
        src="@/assets/site/images/loader.gif"
        v-if="loader === true"
        class="img-fluid loader-width"
        alt
      />
    </div> -->
    <div class="container-fluid">
      <center>
        <div class="product-view-heading">
          <div class="product-view-title">
            <h1 class="display-1 mb-0" style="font-weight: 400">
              <img
                class="giftbox-img mr-2"
                src="@/assets/site/images/gift-box-left.svg"
                alt=""
              />
              GIFTS
              <img
                class="giftbox-img ml-2"
                src="@/assets/site/images/gift-box-right.svg"
                alt=""
              />
            </h1>
          </div>
        </div>
      </center>
      <div v-if="productShow" class="product-slider product-slider-1">
        <div
          id="sm_filterproducts_1610096371335085279"
          class="products-grid mt-4"
        >
          <!-- <div v-if="products.length < 1" class="row">
            <center>
              <strong class="text-danger">No gifts available!!!</strong>
            </center>
        </div> -->
          <div class="row">
            <div
              class="col-md-3"
              v-for="product in products"
              :id="product.productId"
              :product-attribute="product.keywords"
            >
              <div
                @click="ViewProduct(product)"
                class="owl-item active wizard-box"
              >
                <div class="item product product-item">
                  <div class="item-inner clearfix">
                    <div class="box-image">
                      <div class="label-sale pull-right">
                        <b-button
                          size="md"
                          variant="outline-danger"
                          @click.stop="savegift(product)"
                          class="fa fa-gift gift-icon px-3"
                          v-b-popover.hover.top="'Gift'"
                        ></b-button>
                      </div>
                      <a class="product photo product-item-photo" tabindex="-1">
                        <span
                          class="product-image-container product-image-container-2281"
                          style="width: 300px"
                        >
                          <span
                            class="product-image-wrapper"
                            style="padding-bottom: 100%"
                          >
                            <span v-if="product.imageurl">
                              <img
                                class="wizard-image-photo lazyload"
                                :src="product.imageurl"
                                @error="() => (product.imageurl = null)"
                                data-src=""
                                loading="lazy"
                                max-width="300"
                                max-height="300"
                                alt="Fuhlen Mouse for Gamer Mutil Size And Color"
                            /></span>
                            <span v-else>
                              <img
                                class="wizard-image-photo lazyload"
                                src="@/assets/site/images/noimage.jpeg"
                                data-src=""
                                loading="lazy"
                                max-width="300"
                                max-height="300"
                                alt="Fuhlen Mouse for Gamer Mutil Size And Color"/></span
                          ></span>
                        </span>
                      </a>
                      <div class="bottom-action my-3">
                        <a
                          data-post=""
                          @click.stop="savetoFavourite(product)"
                          class="action towishlist btn-action link-wishlist"
                          data-action="add-to-favourite"
                          title="Add to Favourite"
                        >
                          <span>Add to Favourite</span>
                        </a>
                        <a
                          data-post=""
                          @click.stop="savetoWishlist(product)"
                          class="action tocart primary btn-action btn-cart"
                          data-action="add-to-wishlist"
                          title="Add to Wish List"
                        >
                          <span>Add to Wish List</span>
                        </a>
                        <a
                          class="action tocompare btn-action link-compare"
                          @click.stop="compareProduct(product)"
                          data-post=""
                          title="Add to Compare"
                        >
                          <span>Add to Compare</span>
                        </a>

                        <a
                          class="action quickview-handler sm_quickview_handler"
                          title="Quick View"
                          @click.stop="ViewProduct(product)"
                          ><span>Quick View</span></a
                        >
                      </div>
                    </div>
                    <div class="product details product-item-details box-info">
                      <h2 class="product-item-name">
                        <a class="product-item-link">
                          {{ product.productname }}
                        </a>
                      </h2>

                      <div
                        class="price-box price-final_price"
                        data-role="priceBox"
                        data-product-id="2280"
                        data-price-box="product-id-2280"
                      >
                        <span
                          class="price-container price-final_price tax weee"
                        >
                          <span
                            id="product-price-2280"
                            data-price-amount="450"
                            data-price-type="finalPrice"
                            class="price-wrapper"
                            ><span class="price"
                              >{{ product.price["@currency"] }}
                              {{ product.price["#text"] }}</span
                            ></span
                          >
                        </span>
                      </div>
                      <div class="product-reviews-summary short">
                        <div class="rating-summary">
                          <span class="label"><span>Rating:</span></span>
                          <div
                            class="rating-result"
                            id="rating-result_2280"
                            title="87%"
                          >
                            <span style="width: 87%"><span>87%</span></span>
                          </div>
                        </div>
                        <div class="reviews-actions">
                          <a class="action view">
                            &nbsp;<span>Review </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <center>
            <div class="findgft mb-4" v-if="suggested_products.length > 0">
              <h1 class="display-1 mb-0" style="font-weight: 400">
                <span>
                  <img
                    class="giftbox-img mr-2"
                    src="@/assets/site/images/gift-box-left.svg"
                    alt=""
                /></span>
                Suggested Gifts
                <span>
                  <img
                    class="giftbox-img ml-4"
                    src="@/assets/site/images/gift-box-right.svg"
                    alt=""
                /></span>
              </h1>
            </div>
          </center>
          <div class="row">
            <div class="col-md-3" v-for="product in suggested_products">
              <div
                @click="ViewProduct(product)"
                class="owl-item active wizard-box"
              >
                <div class="item product product-item">
                  <div class="item-inner clearfix">
                    <div class="box-image">
                      <a class="product photo product-item-photo" tabindex="-1">
                        <span
                          class="product-image-container product-image-container-2281"
                          style="width: 300px"
                        >
                          <span
                            class="product-image-wrapper"
                            style="padding-bottom: 100%"
                          >
                            <span v-if="product.imageurl">
                              <img
                                class="wizard-image-photo lazyload"
                                :src="product.imageurl"
                                @error="() => (product.imageurl = null)"
                                data-src=""
                                loading="lazy"
                                max-width="300"
                                max-height="300"
                                alt="Fuhlen Mouse for Gamer Mutil Size And Color"
                            /></span>
                            <span v-else>
                              <img
                                class="wizard-image-photo lazyload"
                                src="@/assets/site/images/noimage.jpeg"
                                data-src=""
                                loading="lazy"
                                max-width="300"
                                max-height="300"
                                alt="Fuhlen Mouse for Gamer Mutil Size And Color"
                            /></span>
                          </span>
                        </span>
                      </a>
                      <div class="bottom-action my-3">
                        <a
                          data-post=""
                          @click.stop="savetoFavourite(product)"
                          class="action towishlist btn-action link-wishlist"
                          data-action="add-to-favourite"
                          title="Add to Favourite"
                        >
                          <span>Add to Favourite</span>
                        </a>
                        <a
                          data-post=""
                          @click.stop="savetoWishlist(product)"
                          class="action tocart primary btn-action btn-cart"
                          data-action="add-to-wishlist"
                          title="Add to WishList"
                        >
                          <span>Add to Wish List</span>
                        </a>
                        <a
                          class="action tocompare btn-action link-compare"
                          @click.stop="compareProduct(product)"
                          data-post=""
                          title="Add to Compare"
                        >
                          <span>Add to Compare</span>
                        </a>

                        <a
                          class="action quickview-handler sm_quickview_handler"
                          title="Quick View"
                          @click.stop="ViewProduct(product)"
                          ><span>Quick View</span></a
                        >
                      </div>
                    </div>
                    <div class="product details product-item-details box-info">
                      <h2 class="product-item-name">
                        <a class="product-item-link">
                          {{ product.productname }}
                        </a>
                      </h2>

                      <div
                        class="price-box price-final_price"
                        data-role="priceBox"
                        data-product-id="2280"
                        data-price-box="product-id-2280"
                      >
                        <span
                          class="price-container price-final_price tax weee"
                        >
                          <span
                            id="product-price-2280"
                            data-price-amount="450"
                            data-price-type="finalPrice"
                            class="price-wrapper"
                            ><span class="price"
                              >{{ product.price["@currency"] }}
                              {{ product.price["#text"] }}</span
                            ></span
                          >
                        </span>
                      </div>
                      <div class="product-reviews-summary short">
                        <div class="rating-summary">
                          <span class="label"><span>Rating:</span></span>
                          <div
                            class="rating-result"
                            id="rating-result_2280"
                            title="87%"
                          >
                            <span style="width: 87%"><span>87%</span></span>
                          </div>
                        </div>
                        <!-- <a
                          :href="product.linkurl"
                          target="_blank"
                          class="action primary tocart"
                          id="product-addtocart-button"
                        >
                          <span>Buy Now</span>
                        </a> -->

                        <div class="reviews-actions">
                          <a class="action view">
                            &nbsp;<span>Review </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <center v-if="userData == null" class="mt-4">
          <h3>
            <strong class="text-danger mx-3"
              >Please Login to see more products!!!!</strong
            >
          </h3>
        </center>
      </div>
      <!-- Customer Slider Section -->
      <section class="product-customer-slider-wrapper">
        <div class="product-customer-slider">
          <div class="customer-slider" v-for="slider in customerSliders">
            <img :src="slider.customer_slider" alt="customer slider" />
          </div>
          <div
            class="customer-slider"
            v-if="customerSliders.length < 8"
            v-for="slider in customerSliders"
          >
            <img :src="slider.customer_slider" alt="customer slider" />
          </div>
          <div
            class="customer-slider"
            v-if="customerSliders.length < 12"
            v-for="slider in customerSliders"
          >
            <img :src="slider.customer_slider" alt="customer slider" />
          </div>
          <div
            class="customer-slider"
            v-if="customerSliders.length < 16"
            v-for="slider in customerSliders"
          >
            <img :src="slider.customer_slider" alt="customer slider" />
          </div>
          <div
            class="customer-slider"
            v-if="customerSliders.length < 20"
            v-for="slider in customerSliders"
          >
            <img :src="slider.customer_slider" alt="customer slider" />
          </div>
        </div>
      </section>
    </div>
    <div>
      <!-- <single-product v-if="!productShow" v-bind:gender="gender"></single-product> -->
      <occasion-modal v-on:savetoWishlist="savetoWishlist"></occasion-modal>
      <wizard-result v-bind:wizData="wizData"></wizard-result>
    </div>
  </div>
</template>

<script>
import SingleProduct from "@/views/site/Product";
import OccasionModal from "@/views/site/OccasionModal";
import WizardResult from "@/views/site/wizard/WizardResult";

export default {
  name: "TrendingGiftsView",
  // props: ["products"],
  data() {
    return {
      customerSliders: [],
      products: [],
      suggested_products: [],
      loader: true,
      selected_friend: null,
      productShow: true,
      wizard_data: null,
      action: "",
      occasion_id: null,
      selected_friend_name: null,
      characterTrait: null,
      wishlist_product: null,
      page: 1,
      totalPages: null,
      request_body: null,
      menu: null,
      modal_id: null,
      wizData: null,
      userData: null,
    };
  },
  components: {
    SingleProduct,
    OccasionModal,
    WizardResult,
  },
  created() {
    this.getCustomerSliders();
    this.getData();
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.occasion_id = this.$store.state.occasion_id;

    if (this.$store.state.user_contact) {
      this.selected_friend = this.$store.state.user_contact;
      this.selected_friend_name = this.$store.state.user_contact_name;
    }
    this.characterTrait = this.$store.state.characterTrait;
  },
  methods: {
    getCustomerSliders() {
      this.errors = [];
      let method_type = "";
      let fetch_url = "";
      method_type = "get";
      fetch_url =
        process.env.VUE_APP_URL + "customer/page/getCustomerSliderImages";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.customerSliders = data.slider;
        });
    },
    getData() {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var country_code = localStorage.getItem("countryCode");
      var countryCode = JSON.parse(country_code);
      var fetch_url =
        process.env.VUE_APP_URL + "customer/products/getBestSellers";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          const id = Math.random()
            .toString(36)
            .slice(2);
          this.$store.state.user_contact = null;
          this.$store.state.user_contact_name = null;
          this.$store.state.pet_wizardProducts_items = data.response.items;
          this.products = data.response.items;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getAllrange() {},
    savegift(product) {
      //Save or update User Details
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.api_token;
      var user_id = userdata_array.user.id;
      var fetch_url = process.env.VUE_APP_URL + "customer/savegift";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          product: product,
          user_id: user_id,
          product_id: product.productId,
          user_contact_id: this.selected_friend,
          clickstream_data: this.wizard_data,
          user_action: "SavedForGiftee",
          occasion_id: this.occasion_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.loader = false;
            this.$swal.fire("Good to Go", data.message, "success");
          } else if (data.status === false) {
            this.loader = false;
            this.$swal.fire("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Product", "Error : " + err.message, "error");
        });
    },
    selectOccasion(product) {
      this.wishlist_product = product;
      this.$bvModal.show("occasion-modal");
    },
    savetoWishlist(product) {
      //Save or update User Details
      this.$bvModal.hide("occasion-modal");
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.api_token;
      var user_id = userdata_array.user.id;

      var fetch_url = process.env.VUE_APP_URL + "customer/userwishlist";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          product_id: product.productId,
          //product_name:product.productName,
          product: product,
          //price:product.price.text,
          user_id: user_id,
          clickstream_data: this.wizard_data,
          user_action: "AddToWishlist",
          wishlist_type: "User",
          occasion_id: this.occasion_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.$swal.fire("Good to Go", data.message, "success");
          } else if (data.status === false) {
            this.loader = false;

            this.$swal.fire("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Product", "Error : " + err.message, "error");
        });

      var fetch_url =
        process.env.VUE_APP_URL +
        "customer/products/saveAffilativeNetwork/" +
        product.productId;
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {});

      this.wishlist_product = null;
    },
    savetoFavourite(product) {
      //Save or update User Details
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.api_token;
      var user_id = userdata_array.user.id;
      var fetch_url = process.env.VUE_APP_URL + "customer/favourite";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          product_id: product.productId,
          product: product,
          user_id: user_id,
          clickstream_data: this.wizard_data,
          user_action: "AddToFav",
          wishlist_occasionId: this.occasion_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.loader = false;
            this.$swal.fire("Good to Go", data.message, "success");
          } else if (data.status === false) {
            this.loader = false;
            this.$swal.fire("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Product", "Error : " + err.message, "error");
        });
    },
    compareProduct(product) {
      //Save or update User Details
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.api_token;
      var user_id = userdata_array.user.id;
      var fetch_url = process.env.VUE_APP_URL + "customer/compare";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          product_id: product.productId,
          //product_name:product.productName,
          product: product,
          //price:product.price.text,
          user_id: user_id,
          clickstream_data: this.wizard_data,
          user_action: "compare",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.loader = false;
            this.$swal.fire("Good to Go", data.message, "success");
          } else if (data.status === false) {
            this.loader = false;
            this.$swal.fire("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Product", "Error : " + err.message, "error");
        });
    },
    ViewProduct(product) {
      var selected_friend = this.selected_friend;
      var selected_friend_name = this.selected_friend_name;
      const id = Math.random()
        .toString(36)
        .slice(2);
      this.$router.push({
        path: "/single-product/product/" + id,
        query: {
          singleproduct: product,
          allProducts_items: this.products,
          allProducts_suggested_items: this.suggested_products,
          selected_friend: selected_friend,
          clicked_wizarddata: this.wizard_data,
          selected_friend_name: selected_friend_name,
          type: "wizard",
        },
      });
    },
  },
};
</script>

<style scoped>
.product-view-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cb9b2c;
}

.product-view-heading img {
  height: 60px;
  width: auto;
}

.product-view-heading .product-view-redo {
  flex-shrink: 0;
  margin-left: auto;
}

/* Customer Slider Section Styles */
.product-customer-slider-wrapper {
  width: 100%;
  overflow-x: hidden;
}

.product-customer-slider {
  width: 100%;
  margin: 0 auto;
  margin-top: 80px;
  display: flex;
  align-items: center;
  animation: move 40s linear infinite;
  white-space: nowrap;
  gap: 20px;
}

.product-customer-slider .customer-slider {
  width: 120px;
  height: auto;
  flex-shrink: 0;
}

.product-customer-slider .customer-slider img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1280px) {
  .product-customer-slider {
    width: 100%;
    gap: 32px;
  }
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
